class AnchorServices {
  formatAnchor(string, fieldName) {
    if (fieldName === "name") {
      document.querySelector("input[name=anchor]").value =
        this.escapeHtml(string);
    } else if (fieldName === "anchor") {
      return this.escapeHtml(string);
    }
  }

  escapeHtml(string) {
    return String(string)
      .replace(/[^a-zA-Z0-9-_.]/g, "")
      .replace(/--*/g, "-")
      .replace(/__*/g, "_")
      .replace(/\.\.*/g, ".");
  }
}

export default AnchorServices;
