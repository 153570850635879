var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"input",class:_vm.halfWidth ? 'width-50' : _vm.column ? 'direction-column' : ''},[(_vm.label)?_c('span',{staticClass:"input__label"},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"input__required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input__container",class:_vm.maxLength && _vm.input.length > _vm.maxLength && _vm.$route.name !== 'brokerReview'
        ? '--error'
        : !_vm.maxLength && _vm.error
        ? '--error'
        : '',attrs:{"data-error":_vm.error || 'check this field'}},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"input__field",attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"step":_vm.step,"required":_vm.required,"disabled":_vm.disabled,"data-name":_vm.dataName,"data-type":"currentValue","max-length":_vm.maxLength,"type":"checkbox"},domProps:{"value":_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value,"checked":Array.isArray(_vm.input)?_vm._i(_vm.input,_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value)>-1:(_vm.input)},on:{"input":function($event){_vm.name === 'name' && _vm.required && _vm.$route.name === 'brokerCreate'
          ? _vm.AnchorServices.formatAnchor(_vm.input, _vm.name)
          : _vm.name === 'anchor' && _vm.required && _vm.$route.name === 'brokerCreate'
          ? (_vm.input = _vm.AnchorServices.formatAnchor(_vm.input, _vm.name))
          : '',
          _vm.isFormChangedDetect(true)},"wheel":function($event){return $event.target.blur()},"change":function($event){var $$a=_vm.input,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.input=$$a.concat([$$v]))}else{$$i>-1&&(_vm.input=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.input=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"input__field",attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"step":_vm.step,"required":_vm.required,"disabled":_vm.disabled,"data-name":_vm.dataName,"data-type":"currentValue","max-length":_vm.maxLength,"type":"radio"},domProps:{"value":_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value,"checked":_vm._q(_vm.input,_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value)},on:{"input":function($event){_vm.name === 'name' && _vm.required && _vm.$route.name === 'brokerCreate'
          ? _vm.AnchorServices.formatAnchor(_vm.input, _vm.name)
          : _vm.name === 'anchor' && _vm.required && _vm.$route.name === 'brokerCreate'
          ? (_vm.input = _vm.AnchorServices.formatAnchor(_vm.input, _vm.name))
          : '',
          _vm.isFormChangedDetect(true)},"wheel":function($event){return $event.target.blur()},"change":function($event){_vm.input=_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"input__field",attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"step":_vm.step,"required":_vm.required,"disabled":_vm.disabled,"data-name":_vm.dataName,"data-type":"currentValue","max-length":_vm.maxLength,"type":_vm.type},domProps:{"value":_vm.type === 'number' ? parseFloat(_vm.value) : _vm.value,"value":(_vm.input)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.input=$event.target.value},function($event){_vm.name === 'name' && _vm.required && _vm.$route.name === 'brokerCreate'
          ? _vm.AnchorServices.formatAnchor(_vm.input, _vm.name)
          : _vm.name === 'anchor' && _vm.required && _vm.$route.name === 'brokerCreate'
          ? (_vm.input = _vm.AnchorServices.formatAnchor(_vm.input, _vm.name))
          : '',
          _vm.isFormChangedDetect(true)}],"wheel":function($event){return $event.target.blur()}}}),((_vm.type)==='checkbox'&&(_vm.changesValue !== undefined))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changesInput),expression:"changesInput"}],staticClass:"input__field",class:_vm.maxLength &&
        _vm.changesInput &&
        _vm.changesInput.length > _vm.maxLength &&
        _vm.$route.name !== 'brokerReview'
          ? '--error'
          : !_vm.maxLength && _vm.error
          ? '--error'
          : '',attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"changesValue":_vm.type === 'number' ? parseFloat(_vm.changesValue) : _vm.changesValue,"step":"any","required":_vm.required,"disabled":_vm.disabled,"data-name":_vm.dataName,"data-type":"changesValue","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.changesInput)?_vm._i(_vm.changesInput,null)>-1:(_vm.changesInput)},on:{"wheel":function($event){return $event.target.blur()},"change":function($event){var $$a=_vm.changesInput,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.changesInput=$$a.concat([$$v]))}else{$$i>-1&&(_vm.changesInput=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.changesInput=$$c}}}}):((_vm.type)==='radio'&&(_vm.changesValue !== undefined))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changesInput),expression:"changesInput"}],staticClass:"input__field",class:_vm.maxLength &&
        _vm.changesInput &&
        _vm.changesInput.length > _vm.maxLength &&
        _vm.$route.name !== 'brokerReview'
          ? '--error'
          : !_vm.maxLength && _vm.error
          ? '--error'
          : '',attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"changesValue":_vm.type === 'number' ? parseFloat(_vm.changesValue) : _vm.changesValue,"step":"any","required":_vm.required,"disabled":_vm.disabled,"data-name":_vm.dataName,"data-type":"changesValue","type":"radio"},domProps:{"checked":_vm._q(_vm.changesInput,null)},on:{"wheel":function($event){return $event.target.blur()},"change":function($event){_vm.changesInput=null}}}):(_vm.changesValue !== undefined)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changesInput),expression:"changesInput"}],staticClass:"input__field",class:_vm.maxLength &&
        _vm.changesInput &&
        _vm.changesInput.length > _vm.maxLength &&
        _vm.$route.name !== 'brokerReview'
          ? '--error'
          : !_vm.maxLength && _vm.error
          ? '--error'
          : '',attrs:{"placeholder":_vm.placeholder,"name":_vm.name,"changesValue":_vm.type === 'number' ? parseFloat(_vm.changesValue) : _vm.changesValue,"step":"any","required":_vm.required,"disabled":_vm.disabled,"data-name":_vm.dataName,"data-type":"changesValue","type":_vm.type},domProps:{"value":(_vm.changesInput)},on:{"wheel":function($event){return $event.target.blur()},"input":function($event){if($event.target.composing){ return; }_vm.changesInput=$event.target.value}}}):_vm._e()]),(_vm.showLink)?_c('a',{staticClass:"input__link",attrs:{"href":_vm.input ? _vm.preparedLink : '',"target":"_blank","disabled":_vm.input === ''}},[_vm._v(" Open link ")]):_vm._e(),(_vm.showLinkToContentSite)?_c('a',{staticClass:"input__link",attrs:{"href":("https://www.earnforex.com/forex-brokers/" + _vm.input),"target":"_blank","disabled":_vm.input === ''}},[_vm._v(" Go to broker page ")]):_vm._e(),(_vm.checker)?_c('Checker',{attrs:{"state":_vm.checker}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }