<template>
  <label
    class="input"
    :class="halfWidth ? 'width-50' : column ? 'direction-column' : ''"
  >
    <span class="input__label" v-if="label">
      {{ label }}<span class="input__required" v-if="required">*</span>
    </span>
    <div
      class="input__container"
      :class="
        maxLength && input.length > maxLength && $route.name !== 'brokerReview'
          ? '--error'
          : !maxLength && error
          ? '--error'
          : ''
      "
      :data-error="error || 'check this field'"
    >
      <input
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :value="type === 'number' ? parseFloat(value) : value"
        v-model="input"
        :step="step"
        class="input__field"
        :required="required"
        @input="
          name === 'name' && required && $route.name === 'brokerCreate'
            ? AnchorServices.formatAnchor(input, name)
            : name === 'anchor' && required && $route.name === 'brokerCreate'
            ? (input = AnchorServices.formatAnchor(input, name))
            : '',
            isFormChangedDetect(true)
        "
        :disabled="disabled"
        :data-name="dataName"
        data-type="currentValue"
        :max-length="maxLength"
        @wheel="$event.target.blur()"
      />
      <input
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :changesValue="
          type === 'number' ? parseFloat(changesValue) : changesValue
        "
        step="any"
        v-model="changesInput"
        class="input__field"
        :class="
          maxLength &&
          changesInput &&
          changesInput.length > maxLength &&
          $route.name !== 'brokerReview'
            ? '--error'
            : !maxLength && error
            ? '--error'
            : ''
        "
        :required="required"
        :disabled="disabled"
        :data-name="dataName"
        data-type="changesValue"
        v-if="changesValue !== undefined"
        @wheel="$event.target.blur()"
      />
    </div>
    <a
      :href="input ? preparedLink : ''"
      target="_blank"
      v-if="showLink"
      class="input__link"
      :disabled="input === ''"
    >
      Open link
    </a>

    <a
      :href="`https://www.earnforex.com/forex-brokers/${input}`"
      target="_blank"
      v-if="showLinkToContentSite"
      class="input__link"
      :disabled="input === ''"
    >
      Go to broker page
    </a>

    <Checker v-if="checker" :state="checker" />
  </label>
</template>

<script>
import Checker from "@/components/formFields/Checker.vue";
import { mapMutations } from "vuex";
import AnchorServicesClass from "@/services/AnchorServices";

export default {
  components: { Checker },
  name: "Input",
  props: {
    label: String,
    placeholder: String,
    value: [String, Number],
    changesValue: [String, Number],
    type: {
      type: String,
      default: "text",
    },
    checker: {
      type: String,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    showLinkToContentSite: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataName: {
      type: String,
    },
    error: String,
    column: Boolean,
    maxLength: String,
    step: {
      type: [String, Number],
      default: 0.01,
    },
  },
  data() {
    return {
      approved: "",
      input: "",
      changesInput: "",
      AnchorServices: new AnchorServicesClass(),
    };
  },
  methods: {
    ...mapMutations([
      "updateBrokerInfo",
      "settingDatatoReviewed",
      "isFormChangedDetect",
    ]),
    setApprove(data) {
      this.approved = data;

      let obj = {
        name: this.name,
        data:
          this.type === "number" && data === "false"
            ? parseFloat(this.input)
            : this.type !== "number" && data === "false"
            ? this.input
            : this.type === "number" && data === "true"
            ? parseFloat(this.changesInput)
            : this.type !== "number" && data === "true"
            ? this.changesInput
            : this.type === "number" && data === "unset"
            ? null
            : "",
        reviewed: data,
      };
      this.settingDatatoReviewed(obj);
    },
  },
  created() {
    if (this.value) {
      this.input = this.value;
    }
    if (this.changesValue !== undefined) {
      this.changesInput = this.changesValue;
    }
  },
  watch: {
    value: function () {
      this.input = this.value;
    },
  },
  computed: {
    preparedLink(vm) {
      return vm.input.startsWith("https://") ||
        vm.input.startsWith("http://") ||
        vm.input.startsWith("www.")
        ? vm.input
        : "https://" + vm.input;
    },
  },
};
</script>
