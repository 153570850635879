class GetReviewState {
  reviewedState() {
    if (
      (this.countingCheckers(this.getCheckersValueList())["false"] < 0 &&
        this.countingCheckers(this.getCheckersValueList())["unset"] < 0) ||
      (this.countingCheckers(this.getCheckersValueList())["true"] > -1 &&
        this.countingCheckers(this.getCheckersValueList())["unset"] < 0)
    ) {
      return "DONE";
    } else if (
      this.countingCheckers(this.getCheckersValueList())["true"] < 0 &&
      this.countingCheckers(this.getCheckersValueList())["unset"] < 0
    ) {
      return "CANCELED";
    } else if (
      this.countingCheckers(this.getCheckersValueList())["unset"] >= -1
    ) {
      return "NONE";
    }
  }

  getCheckersValueList() {
    let checkersList = [];
    if (document.querySelector(".form__content")) {
      let checkers = document
        .querySelector(".form__content")
        .querySelectorAll(".checker_set");
      checkers.forEach((item) => {
        item.elements.forEach((input) => {
          if (input.checked) {
            checkersList.push(input.value);
          }
        });
      });
    }
    return checkersList;
  }

  countingCheckers(checkerValues) {
    let resultFalse = checkerValues.findIndex((item) => item === "false");
    let resultTrue = checkerValues.findIndex((item) => item === "true");
    let resultUnset = checkerValues.findIndex((item) => item === "unset");
    return {
      false: resultFalse,
      true: resultTrue,
      unset: resultUnset,
    };
  }
}

export default GetReviewState;
