<template>
  <div class="checker" :class="horizontal ? 'checker--horizontal' : ''">
    <fieldset
      name="checker"
      class="checker_set"
      :class="
        getSelectedTab.innerTab === 'broker rollback'
          ? 'checker_set--rollback'
          : ''
      "
    >
      <div class="checker_content">
        <input
          type="radio"
          v-model="approved"
          value="false"
          @change="toggleComment"
          :disabled="disabled"
          v-if="getSelectedTab.innerTab !== 'broker rollback'"
        />
        <input
          type="radio"
          v-model="approved"
          value="unset"
          @change="test"
          :disabled="disabled"
        />
        <input
          type="radio"
          v-model="approved"
          value="true"
          @change="test"
          :disabled="disabled"
        />
        <span class="indicator"></span>
      </div>
    </fieldset>

    <div
      class="checker__comment checker__comment--top checker__comment--left"
      v-show="approved == 'false' && showComment"
    >
      <textarea
        class="checker__comment_input"
        @blur="showComment = false"
        v-model="comment"
      ></textarea>
      <!-- <input type="text" /> -->
      <button class="btn">
        <img src="@/assets/svg/ico-enter.svg" alt="send" />
      </button>
    </div>
  </div>
</template>

<script>
import GetReviewState from "@/services/GetReviewState";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Checker",
  props: {
    state: {
      type: String,
      default: "unset"
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      approved: "",
      comment: "",
      showComment: null,
      GetReviewState: new GetReviewState(),
      fromType: ""
    };
  },
  computed: {
    ...mapGetters(["getBrokerData", "getCurrentMode", "getSelectedTab"])
  },
  methods: {
    ...mapMutations([
      "settingReviewField",
      "settingAccountDataReviewField",
      "isFormChangedDetect"
    ]),
    approve() {
      this.$emit("set-approve", this.approved);
    },
    async toggleComment() {
      await this.approve();

      this.fromType === "main_data"
        ? this.settingReviewField(
          this.getCurrentMode !== "review"
            ? "NONE"
            : this.GetReviewState.reviewedState()
        )
        : this.fromType === "account_types"
          ? this.settingAccountDataReviewField(
            this.getCurrentMode !== "review"
              ? "NONE"
              : this.GetReviewState.reviewedState()
          )
          : null;

      this.showComment = true;
      let input = !event.target.closest(".mass_togglers_wrap")
        ? event.target
          .closest(".checker")
          .querySelector(".checker__comment_input")
        : null;
      if (
        input &&
        !event.target
          .closest(".checker")
          .classList.contains("checker--no_comment")
      ) {
        setTimeout(() => {
          input.focus();
          input.select();
        }, 0);
      } else if (input) {
        event.target
          .closest(".checker")
          .classList.remove("checker--no_comment");
      }
    },
    async test() {
      await this.approve();
      this.fromType === "main_data"
        ? this.settingReviewField(
          this.getCurrentMode !== "review"
            ? "NONE"
            : this.GetReviewState.reviewedState()
        )
        : this.fromType === "account_types"
          ? this.settingAccountDataReviewField(
            this.getCurrentMode !== "review"
              ? "NONE"
              : this.GetReviewState.reviewedState()
          )
          : null;

      this.isFormChangedDetect(true);
    }
  },
  created() {
  },
  mounted() {
    this.fromType = this.$el.closest("form").getAttribute("id");
    this.approved =
      this.getSelectedTab.innerTab === "broker rollback" &&
      !this.$el.closest(".mass_toggler")
        ? "true"
        : this.state;

    this.approve();
  }
};
</script>
